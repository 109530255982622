<template>
  <v-container
    v-if="selectedNote && foundIndex"
    fill-height
    fluid
    style="background-color: white;"
  >
    <v-card
      flat
      width="100%"
      height="100%"
    >
      <v-toolbar
        flat
        width="100%"
        class="toolbar"
      >
        <v-btn
          icon
          rounded
          @click.stop="$router.back()"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title class="font-weight-light ml-n4">
          {{ $t('common|note') }} {{ foundIndex }}
        </v-toolbar-title>
      </v-toolbar>
      <div
        v-if="!editable"
        class="px-4 ml-4 pb-4"
      >
        <span>{{ selectedNote.value }}</span>
        <v-icon
          small
          class="ml-5"
          color="primary"
          @click="editable = true"
        >
          mdi-pencil
        </v-icon>
      </div>
      <v-textarea
        v-else
        v-model="selectedNote.value"
        :rules="rules"
        :height="$vuetify.breakpoint.height - 210 + 'px'"
        counter
        outlined
        :label="$t('actions|modify_note')"
        class="px-8"
      />
      <v-card-actions>
        <v-btn
          v-if="editable"
          text
          small
          color="blue darken-1"
          @click="editable = false"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-undo
          </v-icon>
          {{ $t('actions|back') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="editable"
          color="primary"
          rounded
          dense
          small
          :disabled="disabled"
          min-width="150"
          :loading="loading"
          class="mb-2"
          @click="submit"
        >
          {{ $t('actions|update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      editable: false,
      loading: false,
      rules: [v => v.length <= 350 || 'Max 350 characters'],
      disabled: true,
      myNotes: [],
      selectedNote: null,
      foundIndex: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    })
  },
  watch: {
    selectedNote: {
      deep: true,
      handler: function (newVal) {
        if (newVal.value.length > 350) {
          this.disabled = true
        } else {
          this.disabled = false
        }
      }
    },
    editable (value) {
      if (value) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    account: {
      handler (value) {
        this.myNotes = value.myNotes || []
      },
      immediate: true
    }
  },
  mounted () {
    for (const key in this.$route.params) {
      if (Object.hasOwnProperty.call(this.$route.params, key)) {
        const obj = this.$route.params[key]
        if (key === 'selectedNote') this.selectedNote = obj
        if (key === 'foundIndex') this.foundIndex = obj
      }
    }
  },
  methods: {
    ...mapActions({
      updateMyNote: 'account/updateMyNote'
    }),
    reset () {
      this.note = ''
    },
    async submit () {
      this.loading = true
      try {
        await this.updateMyNote({ myNotes: this.myNotes })
        this.reset()
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.vdialognew {
  box-shadow: none !important;
}
</style>
